<template>
  <upload-excel :onSuccess="onSuccess"></upload-excel>
</template>

<script setup>
import UploadExcel from '@/components/UploadExcel'
import { importSocpayAPI } from '@/api/social-payment'
import { ElMessage } from 'element-plus'
import { useRouter, useRoute } from 'vue-router'
import { USER_RELATIONS, formatDate } from './utils'

// /**
//  *  1: 筛选数据
//  */
const generateData = (results) => {
  const arr = []
  results.forEach((item) => {
    const userInfo = {}
    Object.keys(item).forEach((key) => {
      if (USER_RELATIONS[key] === 'openTime') {
        userInfo[USER_RELATIONS[key]] = formatDate(item[key])
        return
      }
      userInfo[USER_RELATIONS[key]] = item[key]
    })
    arr.push(userInfo)
  })
  return arr
}

/**
 * 2: 数据解析成功之后的回调
 */
const router = useRouter()
const route = useRoute()
const onSuccess = async ({ header, results }) => {
  const updateData = generateData(results)
  await importSocpayAPI({
    dataset: JSON.stringify(updateData),
    number: route.params.number
  })
  ElMessage.success({
    message: '社会缴费详情导入成功',
    type: 'success'
  })
  router.go(-1)
}
</script>
