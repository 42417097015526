/**
 * 导入数据对应表
 */
export const USER_RELATIONS = {
  姓名: 'name',
  '凭证号/身份证号': 'cardno',
  缴费金额: 'amt',
  缴款事项: 'pay_matters'
}

/**
 * 解析 excel 导入的时间格式
 */
export const formatDate = (numb) => {
  const time = new Date((numb - 1) * 24 * 3600000 + 1)
  time.setYear(time.getFullYear() - 70)
  const year = time.getFullYear() + ''
  const month = time.getMonth() + 1 + ''
  const date = time.getDate() - 1 + ''
  return (
    year +
    '-' +
    (month < 10 ? '0' + month : month) +
    '-' +
    (date < 10 ? '0' + date : date)
  )
}
